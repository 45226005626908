import IntelliProveSDK, {
  AuthenticationMethod,
  BiomarkersResponse,
  UnprocessableVideoResponse,
  IntelliProveStreamingSDK,
  LiveError,
  LiveQualityCheck,
  LiveResults,
  QualityError,
  QualityResponse,
  StreamStatus,
  StreamingMetadata,
} from "intelliprove-streaming-sdk";
import { messageService } from "./messaging.service";
import store from "@/store/store";
import { wait } from "@testing-library/user-event/dist/utils";
import { debugLog } from "./helper";
import { BehaviorSubject } from "rxjs";
import { monitor } from "./monitoring.service";

export enum MeasurementState {
  Abort = "abort",
  Idle = "idle",
  QualityCheck = "quality_check",
  Measurement = "measurement",
}

export class IntelliProveService {
  private static MAX_QUALITY_CHECK_RETRIES = 4;
  private static QC_INTERVAL = 4000; // in ms

  private __instance?: IntelliProveStreamingSDK;
  private timeLeftInterval: NodeJS.Timer | undefined = undefined;
  private qualityCheckRetryCount: number = 0;

  public $state = new BehaviorSubject<MeasurementState>(MeasurementState.Idle);
  public $qualityResponse = new BehaviorSubject<QualityResponse | null>(null);
  public $liveFeedback = new BehaviorSubject<LiveQualityCheck | LiveResults | LiveError | null>(null);
  public $timer = new BehaviorSubject<number | null>(null);

  public onInitialQualityCheckFailed?: (quality_response: QualityResponse) => void;
  public onRecordingStopped?: () => void;

  constructor(actionToken: string, authenticationMethod?: AuthenticationMethod) {
    debugLog(`IntelliProveService:init`, "Initializing IntelliProveSDK...");
    this.__instance = new IntelliProveStreamingSDK(
      actionToken,
      process.env.REACT_APP_API_URL || "",
      undefined,
      authenticationMethod ?? AuthenticationMethod.ActionToken,
    );

    this.__instance.feedbackCallback = (data: LiveQualityCheck | LiveResults | LiveError) => {
      debugLog("IntelliProveService:feedbackCallback", data);
      if (this.__instance?.status) {
        this.$liveFeedback.next(data);
      }
    };

    this.__instance.dataCallback = (stream: StreamStatus) => {
      const timeLeft = this.$state.value === MeasurementState.Idle ? null : stream.remaining;
      this.$timer.next(timeLeft ? Math.max(timeLeft, 0) : null);
    };

    this.__instance.stopCallback = (stream: StreamStatus) => {
      this.onRecordingStopped?.();
    };
  }

  reset() {
    this.$qualityResponse.next(null);
    this.$liveFeedback.next(null);
    this.$timer.next(null);
    this.$state.next(MeasurementState.Idle);
  }

  clearTimer() {
    clearInterval(this.timeLeftInterval);
    this.timeLeftInterval = undefined;
    this.$timer.next(null);
  }

  async abort() {
    this.clearTimer();
    const stateBeforeAbort = this.$state.value;
    this.$state.next(MeasurementState.Abort);
    debugLog("Aborting...", stateBeforeAbort);

    if (stateBeforeAbort === MeasurementState.Measurement) {
      await this.instance?.stop();
      this.$state.next(MeasurementState.Idle);
    }
  }

  get instance(): IntelliProveStreamingSDK | undefined {
    return this.__instance;
  }

  get state() {
    return this.$state.value;
  }

  async finishAndNotifyResult(uuid: string, language?: string) {
    let response: BiomarkersResponse | UnprocessableVideoResponse;
    response = await this.__instance!.finish();

    this.$state.next(MeasurementState.Idle);

    messageService.results(response);

    if (response instanceof UnprocessableVideoResponse) {
      return response;
    }

    this.__instance?.getBuckets(uuid).then((buckets) => {
      if (buckets) {
        messageService.buckets(buckets.buckets);
      }
    });

    this.__instance?.getBucketFeedback(uuid, language).then((feedback) => {
      if (feedback) {
        messageService.bucketSummaries(feedback.summaries);
      }
    });

    return response;
  }

  /**
   * @param uuid
   * @returns boolean - indicates finish has been awaited until api calls completed or left behind
   */
  async finish(): Promise<BiomarkersResponse | UnprocessableVideoResponse | undefined> {
    let timeout: NodeJS.Timer;
    const skipResults = store.getState().setting.pluginSettings?.skip_results ?? false;

    if (!this.__instance) {
      throw new Error(`IntelliProveService: SDK is not initialized yet!`);
    }

    let finishTimeOut = false;

    return await new Promise((resolve, reject) => {
      this.__instance?.finish().then((result) => {
        if (finishTimeOut) return;
        finishTimeOut = true;
        clearTimeout(timeout);
        this.$state.next(MeasurementState.Idle);

        messageService.results(result);
        return resolve(result);
      });

      if (!skipResults) {
        timeout = setTimeout(() => {
          if (finishTimeOut) {
            return;
          }

          debugLog(`finish() timeout reached, finishing finish() without waiting for result!`);
          finishTimeOut = true;
          resolve(undefined);
        }, 3000);
      }
    });
  }

  stopQualityCheckLoop(aborted: boolean = false) {
    this.$state.next(MeasurementState.Idle);
    debugLog("stopQualityCheckLoop:", aborted ? "aborted" : "stopped");
  }

  canStartMeasurement() {
    if (this.$state.value === MeasurementState.Measurement) {
      debugLog("canStartMeasurement", `Prevented due to ${this.$state.value}`);
      return false;
    }
    return true;
  }

  async startMeasurement(metadata: StreamingMetadata): Promise<StreamStatus | null> {
    if (this.$state.value === MeasurementState.Abort) {
      debugLog(`Performing measurement...`, "aborted...");
      this.$state.next(MeasurementState.Idle);
      return null;
    }

    debugLog(`Performing measurement...`, "starting...");

    let duration = store.getState().setting.duration ?? 20_000;

    try {
      this.$state.next(MeasurementState.QualityCheck);
      await this.instance?.start(store.getState().setting.duration ?? 20_000, metadata);
      this.qualityCheckRetryCount = 0; // Reset count after successful check
    } catch (e) {
      if (!(e instanceof QualityError)) {
        throw e;
      }

      let qError = e as QualityError;
      this.qualityCheckRetryCount += 1;
      let wasLastAttempt = this.qualityCheckRetryCount >= IntelliProveService.MAX_QUALITY_CHECK_RETRIES;

      monitor.trackQualityCheck(qError.quality.error_code, true, wasLastAttempt);
      if (wasLastAttempt) {
        this.qualityCheckRetryCount = 0;
        this.onInitialQualityCheckFailed?.(qError.quality);
        this.$state.next(MeasurementState.Idle);
        return null;
      } else {
        this.$qualityResponse.next(qError.quality);
        await wait(IntelliProveService.QC_INTERVAL);
        this.$qualityResponse.next(null);
        await wait(1000);
        return await this.startMeasurement(metadata);
      }
    }

    messageService.setMetadata(this.instance!.status!.uuid ?? "", metadata.patient, metadata.performer);
    monitor.setMetadata(this.instance!.status!.uuid ?? "", metadata.patient, metadata.performer);

    monitor.trackRecordingStarted(duration);

    this.$state.next(MeasurementState.Measurement);
    debugLog(`Performing measurement...`, "started");

    this.$qualityResponse.next(new QualityResponse({ quality_error_code: 0 }));
    setTimeout(() => {
      // Clear qualityResponse after 3s
      this.$qualityResponse.next(null);
    }, IntelliProveService.QC_INTERVAL);

    messageService.recordingStarted();
    return this.instance!.status;
  }
}
